@font-face {
    font-family: 'DancingScript';
    src: url('./fonts/DancingScript-Bold.ttf') format('truetype');
    font-weight: 700;
    /* Normal weight */
    font-style: normal;
}

@font-face {
    font-family: 'DMSerifDisplay-Italic';
    src: url('./fonts/DMSerifDisplay-Italic.ttf') format('truetype');
    font-weight: 700;
    /* Normal weight */
    font-style: normal;
}

@font-face {
    font-family: 'DMSerifDisplay-Regular';
    src: url('./fonts/DMSerifDisplay-Regular.ttf') format('truetype');
    font-weight: 700;
    /* Normal weight */
    font-style: normal;
}
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=DM+Serif+Display:ital@0;1&display=swap');

body {
  margin: 0;
  font-family: 'DM Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.content-section {
    background: #f7f8fa;
  }

    .task-column {
      width: 500px;
      /* Adjust the width as needed */
    }

.remain-days {
    border: 1px solid #d2d9e5;
    width: fit-content;
    padding: 2px 14px;
    border-radius: 30px;
    font-size: 15px;
    background: #f1f3f6;
    margin-top: 8px;
  }

  .button-beige {
    background: #ebcb9c !important;
  }

  .fond-bleu {
    background: #005975;
  }

.skeleton {
  background-color: #ddd; /* Light grey background */
  animation: shimmer 1s infinite; /* Apply the animation, now with a duration of 1 second */
}

@keyframes shimmer {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5; /* The opacity changes to 0.5 in the middle of the animation */
  }
  100% {
    opacity: 1;
  }
}

.names-skeleton {
  width: 260px;
  /* Adjust width as needed */
  height: 25px;
  /* Adjust height to match the text size */
  background-color: #ddd;
  border-radius: 4px;
  animation: shimmer 1s infinite;
  margin-top:11px;
  margin-bottom:5px;
}

@keyframes shimmer {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.text-skeleton {
  width: 50%;
  /* Adjust based on your layout */
  height: 1.1rem;
  /* Approximate height of your text */
  background-color: #ddd;
  border-radius: 4px;
  animation: shimmer 1s infinite;
  margin-top:8px;
}

.card-text-skeleton {
  width: 50%;
  /* Adjust based on your layout */
  height: 1.1rem;
  /* Approximate height of your text */
  background-color: #ddd;
  border-radius: 4px;
  animation: shimmer 1s infinite;
  margin-top: 25px;
}

.icon-skeleton {
  width: 20px;
  /* Adjust based on the size of your icons */
  height: 20px;
  /* Adjust based on the size of your icons */
  background-color: #ddd;
  border-radius: 50%;
  animation: shimmer 1s infinite;
}

@keyframes shimmer {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.countdown-skeleton {
  width: 240px;
  /* Adjust based on the layout and size of your countdown text */
  height: 30px;
  /* Adjust to match the height of your countdown text */
  background-color: #ddd;
  border-radius: 44px;
  animation: shimmer 1s infinite;
  margin-top:7px;
}

.tab-beige {
    background-color: #ebcb9c !important;
    color: #fff !important;
  }